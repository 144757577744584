import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import history from "utils/history";

// Auth0
import { Auth0Provider } from "react-auth0-spa";
import * as serviceWorker from "serviceWorker";
import config from "auth_config.json";

import "assets/css/nucleo-icons.css";

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin}
      audience={config.audience}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      <App />
    </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
