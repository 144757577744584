import React from "react";
import ReactDOM from 'react-dom';
import { Row, Col, Card, CardImg, CardImgOverlay, CardBody, Button, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from "reactstrap";
import { CollectionView, GridLayout, Size, Insets } from "collection-view"
import InvaderCellCard from "components/Cards/InvaderCellCard"
import Loading from "components/Loading"
import history from "utils/history";
import { diff, Deletion, Insertion, Update, Move } from 'heckel-diff-items'

const city = {
    "name": "",
    "cityCode": "",
    "latitude": "",
    "longitude": "",
    "points": "",
    "waveCount": "",
    "nbSpaceInvader": "",
    "country": "",
    "flashedCount": "",
    "notDefinitivelyDeadCount": "",
    "aliveCount": ""
}

class Content extends React.Component {

    constructor(props) {
        super(props)
        this.items = props.items
    }

    getCount() {
        return this.items.length
    }

    configureElement(element, index) {
        const item = this.items[index]
        // render item using React
        ReactDOM.render(<InvaderCellCard {...this.props} invader={item} />, element)
    }

    installView(element) {
        const layout = new GridLayout({
            itemSize: new Size(300, 100),
            insets: new Insets(10, 10, 10, 10)
        })
        var collectionView = new CollectionView(element, layout, this)
        collectionView.thresholds = {left: 0, top: 0, right: 0, bottom: 80000}
        this.view = collectionView
    }

    uninstallView() {
        if (!this.view) {
            return
        }

        this.view.uninstall((element => {
            // elements were rendered using React, clean up
            ReactDOM.unmountComponentAtNode(element)
        }))
    }

    onRef = (element) => {
        if (!element) {
            this.uninstallView()
            return
        }
        this.installView(element)
    }

    update(items) {
        const oldItems = this.items
        this.items = items

        // can't update if the view isn't installed yet
        if (!this.view) {
            return
        }

        const ops = diff(oldItems, items)

        const removed = []
        const added = []
        const moved = new Map()

        ops.forEach((op) => {
            if (op instanceof Deletion) {
                removed.push(op.index)
            } else if (op instanceof Insertion) {
                added.push(op.index)
            }  else if (op instanceof Move) {
                moved.set(op.fromIndex, op.toIndex)
            } else if (op instanceof Update) {
                removed.push(op.index)
                added.push(op.index)
            }
        })

        //console.log(JSON.stringify([removed, added, Array.from(moved.entries())]))

        this.view.changeIndices(removed, added, moved)
    }

    shouldComponentUpdate() {
        // prevent component from re-rendering
        return false
    }

    componentWillReceiveProps(nextProps) {
        // manually update
        this.update(nextProps.items)
    }

    componentWillUnmount() {
        this.uninstallView()
    }

    render() {
        return (
            <div id="wrapper">
                <div id="scroll" ref={this.onRef}></div>
            </div>
        )
    }
}

class City extends React.Component {
    constructor(props) {
         super(props)
         this.myRef = React.createRef()
         this.state = {
             loading: <Loading />,
             invaders: [],
             invadersToDisplay: [],
             fullInvadersList: [],
             filteredInvadersList: [],
             resultsCount: "Loading ...",
             shownCount: "",
             token: '',
             cityCode: '',
             city: city,
             limit: 250,
             pagination: [],
             currentPage: 1,
             isLoading: true,
             filtersShow: false,
             flashedFilter: 'all',
             statusFilter: 'All',
             spottedFilter: 'all',
             pointsFilter: 'All',
             shapeFilter: 'All',
             colorFilter: 'All',
             backgroundColorFilter: 'All',
         }
         this.getCity = this.getCity.bind(this);
         this.setPage = this.setPage.bind(this);
         this.toggleModalFilters = this.toggleModalFilters.bind(this);
         this.handleFiltersChange = this.handleFiltersChange.bind(this);
         this.applyFilter = this.applyFilter.bind(this);
     }

     onChangeClick = () => {
         this.setState((prevState) => ({
             changed: !prevState.changed
         }))
     }

     async getCity(){
         this.setState({ isLoading: true })
         this.setState({ loading: <Loading /> })
         this.setState({ resultsCount: "Loading ..." })
         this.setState({ shownCount: "" })
         this.setState({
             flashedFilter: 'all',
             statusFilter: 'All',
             spottedFilter: 'all',
             pointsFilter: 'All',
             shapeFilter: 'All',
             colorFilter: 'All',
             backgroundColorFilter: 'All',
             filtersApplied: ""
         })
         try {
            const token = await this.props.getTokenSilently();

            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            const cityCode = params.get('cityCode');
            var formdata = new FormData();
            formdata.append("cityCode", cityCode);

            const response = await fetch("https://secure.api.invadex.space/private/getCity",
                {
                  method: 'POST',
                  headers: {
                      Authorization: `Bearer ${token}`
                  },
                  mode: 'cors',
                  cache: 'default',
                  body: formdata
                }
            );

            const responseData = await response.json();

            let invaders = responseData.map((invader, i) => {
                return (
                    invader
                )
            })
            invaders = invaders.reverse();
            this.setState({ fullInvadersList : invaders })
            this.setState({ invaders : invaders }, function() {
                //const invadersToDisplay = invaders.slice(0, this.state.limit)
                //this.setState({ invadersToDisplay: invadersToDisplay })
                //this.setState({ pagination: this.renderPagination() })
                this.setState({ loading: undefined })
                this.setState({ isLoading: false })
                this.setPage(1)
            })
        } catch (error) {
            console.error(error);
            this.setState({ isLoading: false })
        }
    };

    async getCities(){
      try {
        const token = await this.props.getTokenSilently();

        const response = await fetch("https://secure.api.invadex.space/private/getCities",
            {
              method: 'POST',
              headers: {
                  Authorization: `Bearer ${token}`
              },
              mode: 'cors',
              cache: 'default'
            }
        );

        const responseData = await response.json();

        let cities = responseData.map((city, i) => {
            if(city.cityCode === this.state.cityCode){
                this.setState({ city : city })
            }
        })

      } catch (error) {
        console.error(error);
      }
    };

    componentDidMount() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const cityCode = params.get('cityCode');

        this.setState({cityCode: cityCode});

        this.getCities()
        this.getCity()
    }

    goToLive() {
        history.push("/live?cityCode="+this.state.city.cityCode, {city: city})
    }

    setPage(value) {
        var page = parseInt(value)
        this.setState({ currentPage : page })
        const offset = (page-1)*this.state.limit
        const end = offset + this.state.limit
        const invadersToDisplay = this.state.invaders.slice(offset, end)
        var resultsCount = this.state.invaders.length + " results"
        if(this.state.invaders.length <= 1) {
            resultsCount = "1 result"
        }
        this.setState({ invadersToDisplay: invadersToDisplay}, function() {
            var shownCount = ""
            if(this.state.invadersToDisplay.length != this.state.invaders.length){
                shownCount = this.state.invadersToDisplay.length + "/"
            }
            this.setState({ shownCount: shownCount })

            this.setState({ pagination: this.renderPagination(page)})
            this.setState({ resultsCount: resultsCount })
        })
    }

    renderPagination() {
        const pageCount = (this.state.invaders.length/this.state.limit).toFixed()
        if(pageCount>1){
            var pageItems = [];

            // PREVIOUS
            pageItems.push(
                <PaginationItem
                    disabled={ parseInt(this.state.currentPage) === 1 ? true : false }
                >
                    <PaginationLink
                        value={this.state.currentPage-1}
                        onClick={e => this.setPage(e.target.value)}
                    >
                        Previous
                    </PaginationLink>
                </PaginationItem>
            );
            // NUMBERS
            for (var i=1; i <= pageCount; i++) {
                pageItems.push(
                    <PaginationItem
                        active={ parseInt(this.state.currentPage) === i ? true : false }
                    >
                        <PaginationLink
                            value={i}
                            onClick={e => this.setPage(e.target.value)}
                        >
                            {i}
                        </PaginationLink>
                    </PaginationItem>
                );
            }

            // NEXT
            pageItems.push(
                <PaginationItem
                    disabled={ parseInt(this.state.currentPage) === parseInt(pageCount) ? true : false }
                >
                    <PaginationLink
                        value={this.state.currentPage+1}
                        onClick={e => this.setPage(e.target.value)}
                    >
                        Next
                    </PaginationLink>
                </PaginationItem>
            );
            return pageItems.map(page => (
                <>
                    {page}
                </>
            ));
        }
    }

    toggleModalFilters() {
        this.setState({
            filtersShow: !this.state.filtersShow
        })
    }

    handleFiltersChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, function() {
          this.applyFilter();
        });
    };

    applyFilter() {
        var fiteredInvaders = this.state.fullInvadersList
        var filtersApplied = 0

        // Flashed
        if(this.state.flashedFilter === 'all'){
            fiteredInvaders = this.state.fullInvadersList
        }
        if(this.state.flashedFilter === 'flashed'){
            fiteredInvaders = this.state.fullInvadersList.filter(invader => invader.isFlashed)
            filtersApplied += 1
        }
        if(this.state.flashedFilter === 'notflashed'){
            fiteredInvaders = this.state.fullInvadersList.filter(invader => !invader.isFlashed)
            filtersApplied += 1
        }

        // Status
        if(this.state.statusFilter === 'Alive'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.statusDelta >= 8)
            filtersApplied += 1
        }
        if(this.state.statusFilter === 'Unflashable'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.statusDelta >= 6 && invader.statusDelta <= 7)
            filtersApplied += 1
        }
        if(this.state.statusFilter === 'Dead'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.statusDelta >= 2 && invader.statusDelta <= 5)
            filtersApplied += 1
        }
        if(this.state.statusFilter === 'Definitively Dead'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.statusDelta === 1)
            filtersApplied += 1
        }
        if(this.state.statusFilter === 'Unknown'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.statusDelta === 0)
            filtersApplied += 1
        }

        // Spotted
        if(this.state.spottedFilter === 'spotted'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.coordinates.latitude === 1)
            filtersApplied += 1
        }
        if(this.state.spottedFilter === 'notspotted'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.coordinates.latitude === 0)
            filtersApplied += 1
        }

        // Points
        if(this.state.pointsFilter === '10 PTS'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.points === 10)
            filtersApplied += 1
        }
        if(this.state.pointsFilter === '20 PTS'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.points === 20)
            filtersApplied += 1
        }
        if(this.state.pointsFilter === '30 PTS'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.points === 30)
            filtersApplied += 1
        }
        if(this.state.pointsFilter === '40 PTS'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.points === 40)
            filtersApplied += 1
        }
        if(this.state.pointsFilter === '50 PTS'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.points === 50)
            filtersApplied += 1
        }
        if(this.state.pointsFilter === '100 PTS'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.points === 100)
            filtersApplied += 1
        }
        if(this.state.pointsFilter === 'Unknown'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.points === 0)
            filtersApplied += 1
        }

        // Shape
        if(this.state.shapeFilter != 'All'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.shape === this.state.shapeFilter)
        }

        // Color
        if(this.state.colorFilter != 'All'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.invaderColor.includes(this.state.colorFilter))
        }

        // Background Color
        if(this.state.backgroundColorFilter != 'All'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.backgroundColor.includes(this.state.backgroundColorFilter))
        }

        if(filtersApplied > 0){
            if(filtersApplied == 1){
                this.setState({ filtersApplied: "(1 filter)" })
            } else {
                this.setState({ filtersApplied: "(" + filtersApplied + " filters)" })
            }
        } else {
            this.setState({ filtersApplied: "" })
        }

        this.setState({ invaders: fiteredInvaders }, function() {
            this.setPage(1)
        })
    }

    resetFilters() {
        this.setState({
            flashedFilter: 'all',
            statusFilter: 'All',
            spottedFilter: 'all',
            pointsFilter: 'All',
            shapeFilter: 'All',
            colorFilter: 'All',
            backgroundColorFilter: 'All'
        }, function() {
          this.applyFilter();
          this.toggleModalFilters()
        });
    }

    render() {
        return [
            <div className="content">
                <Modal
                    size="lg"
                    isOpen={this.state.filtersShow}
                    toggle={this.toggleModalFilters}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <ModalHeader>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                            onClick={(el, state) => this.toggleModalFilters(el, state)}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        <b>Filters</b> | {this.state.resultsCount}
                    </ModalHeader>
                    <ModalBody>
                        <p><Label for="flashed"><b>FLASHED</b></Label></p>
                        <FormGroup check className="form-check-radio form-check-inline">
                            <Label className="form-check-label">
                                <Input type="radio" name="flashedFilter" id="flashed-all" value="all" onChange={this.handleFiltersChange} defaultChecked={ this.state.flashedFilter === "all" ? true : false } />All<span className="form-check-sign"></span>
                            </Label>
                            <Label className="form-check-label">
                                <Input type="radio" name="flashedFilter" id="flashed-flashed" value="flashed" onChange={this.handleFiltersChange} defaultChecked={ this.state.flashedFilter === "flashed" ? true : false } />Yes<span className="form-check-sign"></span>
                            </Label>
                            <Label className="form-check-label">
                                <Input type="radio" name="flashedFilter" id="flashed-notflashed" value="notflashed" onChange={this.handleFiltersChange} defaultChecked={ this.state.flashedFilter === "notflashed" ? true : false } />No<span className="form-check-sign"></span>
                            </Label>
                        </FormGroup>
                        <br/><br/>
                        <p><Label for="statusFilter"><b>INVADER STATUS</b></Label></p>
                        <FormGroup>
                            <Input type="select" name="statusFilter" id="statusFilter" onChange={this.handleFiltersChange}  defaultValue={this.state.statusFilter}>
                                <option>All</option>
                                <option>Alive</option>
                                <option>Unflashable</option>
                                <option>Dead</option>
                                <option>Definitively Dead</option>
                                <option>Unknown</option>
                            </Input>
                        </FormGroup>
                        <br/>
                        <p><Label for="pointsFilter"><b>POINTS</b></Label></p>
                        <FormGroup>
                            <Input type="select" name="pointsFilter" id="pointsFilter" onChange={this.handleFiltersChange} defaultValue={this.state.pointsFilter}>
                                <option>All</option>
                                <option>10 PTS</option>
                                <option>20 PTS</option>
                                <option>30 PTS</option>
                                <option>40 PTS</option>
                                <option>50 PTS</option>
                                <option>100 PTS</option>
                                <option>Unknown</option>
                            </Input>
                        </FormGroup>
                        <br/>
                        <p><Label for="shapeFilter"><b>BACKGROUND SHAPE</b></Label></p>
                        <FormGroup>
                            <Input type="select" name="shapeFilter" id="shapeFilter" onChange={this.handleFiltersChange} defaultValue={this.state.shapeFilter}>
                                <option>All</option>
                                <option>square</option>
                                <option>round</option>
                                <option>free</option>
                                <option>border</option>
                                <option>shadow</option>
                                <option>none</option>
                            </Input>
                        </FormGroup>
                        <br/>
                        <p><Label for="colorFilter"><b>INVADER COLOR</b></Label></p>
                        <FormGroup>
                            <Input type="select" name="colorFilter" id="colorFilter" onChange={this.handleFiltersChange} defaultValue={this.state.colorFilter}>
                                <option>All</option>
                                <option>White</option>
                                <option>Beige</option>
                                <option>Pink</option>
                                <option>Red</option>
                                <option>Orange</option>
                                <option>Yellow</option>
                                <option>Green</option>
                                <option>Blue</option>
                                <option>Purple</option>
                                <option>Black</option>
                                <option>Brown</option>
                                <option>Grey</option>
                                <option>Silver</option>
                                <option>Gold</option>
                                <option>Mirror</option>
                                <option>Multicolor</option>
                            </Input>
                        </FormGroup>
                        <br/>
                        <p><Label for="backgroundColorFilter"><b>BACKGROUND COLOR</b></Label></p>
                        <FormGroup>
                            <Input type="select" name="backgroundColorFilter" id="backgroundColorFilter" onChange={this.handleFiltersChange} defaultValue={this.state.backgroundColorFilter}>
                                <option>All</option>
                                <option>White</option>
                                <option>Beige</option>
                                <option>Pink</option>
                                <option>Red</option>
                                <option>Orange</option>
                                <option>Yellow</option>
                                <option>Green</option>
                                <option>Blue</option>
                                <option>Purple</option>
                                <option>Black</option>
                                <option>Brown</option>
                                <option>Grey</option>
                                <option>Silver</option>
                                <option>Gold</option>
                                <option>Mirror</option>
                                <option>Multicolor</option>
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="default"
                            onClick={(el, state) => this.resetFilters(el, state)}
                        >
                            Reset
                        </Button>
                        <Button
                            color="default"
                            onClick={(el, state) => this.toggleModalFilters(el, state)}
                        >
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
                <Card className="cityHeader">
                    <CardImg width="100%" src={"https://ressources.invadex.space/images/cities-normalized/" + this.state.cityCode + ".jpg"} alt="Card image cap" />
                    <CardImgOverlay>
                        <CardBody>
                            <table width="100%">
                                <tr height="100px">
                                    <td>
                                        &nbsp;
                                    </td>
                                    <td className="text-right">
                                        <Button
                                            color="default"
                                            onClick={(el, state) => this.goToLive(el, state)}
                                        >
                                            Live
                                        </Button>
                                    </td>
                                </tr>
                                <tr height="100px" class="linear-gradient-background">
                                    <td>
                                            <h2>{this.state.city.country}</h2>
                                            <h1>{this.state.city.name}</h1>
                                            <h3>{this.state.city.points} PTS</h3>
                                    </td>
                                    <td style={{textAlign: 'right', varticalAlign: 'bottom'}}>
                                        &nbsp;
                                        <h1>{this.state.city.flashedCount}</h1>
                                        <h3>/{this.state.city.nbSpaceInvader}</h3>
                                    </td>
                                </tr>
                            </table>
                        </CardBody>
                    </CardImgOverlay>
                </Card>
                <Row>
                    <Col xs="6" className={"results"}><b>{this.state.shownCount}{this.state.resultsCount}</b> {this.state.filtersApplied}</Col>
                    <Col xs="6">
                        <Button
                            {...this.props}
                            color="default"
                            onClick={this.toggleModalFilters}
                            className="btn btn-round btn-icon btn-active"
                            style={{float: 'right'}}
                        >
                            <i className={"tim-icons icon-bullet-list-67"}></i>
                        </Button>
                    </Col>
                 </Row>

                <Pagination listClassName="justify-content-center">
                    {this.state.pagination}
                </Pagination>
                <div id="content" key="content">
                    {this.state.loading}
                    <Content items={this.state.invadersToDisplay} />
                </div>
                <Pagination listClassName="justify-content-center">
                    {this.state.pagination}
                </Pagination>
            </div>
          ]
      }
}

export default City;
