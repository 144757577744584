import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import logo from "assets/img/icon-rounded-100.png";

var ps;

class AppLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "invadex",
      sidebarOpened:
        document.documentElement.className.indexOf("nav-open") !== -1
    };
  }
  componentDidMount() {
    // Styles
    //require("assets/css/black-dashboard-react.css");
    require("assets/css/black-dashboard-pro-react.css");
    require("assets/css/invadex.css");

    document.body.classList.add("white-content");

    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/") {
        const Component = prop.component;
            return (
              <Route
                path={prop.layout + prop.path}
                component={() => <Component {...this.props}/>}
                key={key}
              />
            );
      } else {
        return null;
      }
    });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Invadex";
  };

  render() {
      return (
          <>
            <div className="wrapper">
              <Sidebar
                {...this.props}
                routes={routes}
                bgColor={this.state.backgroundColor}
                logo={{
                  outterLink: "https://www.invadex.space/",
                  text: "Invadex",
                  imgSrc: logo
                }}
                toggleSidebar={this.toggleSidebar}
              />
              <div
                className="main-panel"
                ref="mainPanel"
                data={this.state.backgroundColor}
              >
                <AppNavbar
                  {...this.props}
                  brandText={this.getBrandText(this.props.location.pathname)}
                  toggleSidebar={this.toggleSidebar}
                  sidebarOpened={this.state.sidebarOpened}
                />
                <Switch>
                  {this.getRoutes(routes)}
                  <Redirect from="*" to="/lastupdates"/>
                </Switch>
                {// we don't want the Footer to be rendered on map page
                this.props.location.pathname.indexOf("map") !== -1 ? null : (
                  <Footer fluid />
                )}
              </div>
            </div>
          </>
        );
  }
}

export default AppLayout;
