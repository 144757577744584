import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import history from "utils/history";

import { useAuth0 } from "react-auth0-spa";
import Loading from "components/Loading";

// Layouts
import AppLayout from "layouts/AppLayout.js";

const App = () => {
  const { loading, isAuthenticated, user, logout, getTokenSilently } = useAuth0();
  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin
    });

  if (loading) {
    return <Loading />;
  }

  return (
      <Router history={history}>
        <Switch>
          <PrivateRoute path="/" component={props => <AppLayout loading={loading} isAuthenticated={isAuthenticated} user={user} logoutWithRedirect={logoutWithRedirect} logout={logout} getTokenSilently={getTokenSilently} {...props} /> } />
        </Switch>
      </Router>
  );
};

export default App;
