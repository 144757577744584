import React from "react";
import ReactDOM from 'react-dom';
import { Router, NavLink } from "react-router-dom";
import { Card, CardImg, CardImgOverlay, CardBody } from "reactstrap";
import { CollectionView, GridLayout, Size, Insets } from "collection-view"
import history from "utils/history";
import Loading from "components/Loading"

class CollectionViewCell extends React.Component {
    render() {
        const {item} = this.props;
        return (
            <Card className="cityCollectionViewCell">
                <Router history={history}>
                    <NavLink
                      to={"/city?cityCode=" + item.cityCode}
                    >
                        <CardImg top src={"https://ressources.invadex.space/images/cities-squared/" + item.cityCode + ".jpg"} alt={item.name} style={{height: "100%"}}/>
                        <CardImgOverlay>
                            <table width="100%" height="100%">
                                <tr height="50%">
                                </tr>
                                <tr height="50%" class="linear-gradient-background">
                                    <td height="100%" >
                                        <CardBody>
                                            <h2>{item.country}</h2>
                                            <h1>{item.name}</h1>
                                            <h3>{item.flashedCount}/{item.nbSpaceInvader} invaders</h3>
                                        </CardBody>
                                    </td>
                                </tr>
                            </table>
                        </CardImgOverlay>
                    </NavLink>
                </Router>
            </Card>
        )
    }
}

class Content extends React.Component {

    constructor(props) {
        super(props)

        // NOTE: *not* using state
        this.items = props.items
    }

    getCount() {
        return this.items.length
    }

    configureElement(element, index) {
        const item = this.items[index]
        // render item using React
        ReactDOM.render(<CollectionViewCell item={item} />, element)
    }

    installView(element) {
        const layout = new GridLayout({
            itemSize: new Size(145, 145),
            insets: new Insets(10, 10, 10, 10)
        })
        const collectionView = new CollectionView(element, layout, this)
        collectionView.thresholds = {left: 0, top: 0, right: 0, bottom: 80000}
        this.view = collectionView
    }

    uninstallView() {
        if (!this.view) {
            return
        }
        this.view.uninstall((element => {
            // elements were rendered using React, clean up
            ReactDOM.unmountComponentAtNode(element)
        }))
    }

    onRef = (element) => {
        if (!element) {
            this.uninstallView()
            return
        }
        this.installView(element)
    }

    update(items) {
        /*const oldItems = this.items
        this.items = items

        // can't update if the view isn't installed yet
        if (!this.view) {
            return
        }

        const ops = diff(oldItems, items)

        const removed = []
        const added = []
        const moved = new Map()

        ops.forEach((op) => {
            if (op instanceof Deletion) {
                removed.push(op.index)
            } else if (op instanceof Insertion) {
                added.push(op.index)
            }  else if (op instanceof Move) {
                moved.set(op.fromIndex, op.toIndex)
            } else if (op instanceof Update) {
                removed.push(op.index)
                added.push(op.index)
            }
        })

        console.log(JSON.stringify([removed, added, Array.from(moved.entries())]))

        this.view.changeIndices(removed, added, moved)*/
    }

    shouldComponentUpdate() {
        // prevent component from re-rendering
        return false
    }

    componentWillReceiveProps(nextProps) {
        // manually update
        this.update(nextProps.items)
    }

    componentWillUnmount() {
        this.uninstallView()
    }

    render() {
        return (
            <div ref={this.onRef}></div>
        )
    }
}

class CityGuides extends React.Component {
    constructor(props) {
         super(props)
         this.state = {
             changed: false,
             invaders: [],
             token: '',
             cities: <Loading />
         }
     }

     onChangeClick = () => {
        this.setState((prevState) => ({
            changed: !prevState.changed
        }))
    }

    onHideClick = () => {
        this.setState((prevState) => ({
            hidden: !prevState.hidden
        }))
    }

    async callApi(){
      try {
        const token = await this.props.getTokenSilently();

        const response = await fetch("https://secure.api.invadex.space/private/delta/getCities",
            {
              method: 'POST',
              headers: {
                  Authorization: `Bearer ${token}`
              },
              mode: 'cors',
              cache: 'default'
            }
        );

        const responseData = await response.json();

        let cities = responseData.map((city, i) => {
            return (
                city
            )
        })
        cities = <Content items={cities} />

        this.setState({ cities : cities })

      } catch (error) {
        console.error(error);
      }
    };

    componentDidMount() {
        this.callApi()
    }

    render() {
        return [
            <div className="content">
                <div id="content" key="content">
                    {this.state.cities}
                </div>
            </div>
        ]
    }
}

export default CityGuides;
