import React from "react";
import { Router, NavLink } from "react-router-dom";
import { Card, CardImg, CardImgOverlay, CardBody, CardTitle, CardSubtitle, CardText, Row, Col } from "reactstrap";
import history from "utils/history";

class InvaderCellCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            style: "invaderCollectionViewCell " + this.props.style,
            invader: this.props.invader,
            siUid: 0,
            name: "",
            photoIDURL: "https://ressources.invadex.space/images/no-image.png",
            status: "",
            zone: "",
            points: "",
            zonePointsAggregate: "",
            isFlashed: this.props.invader.isFlashed,
            isFlashedClass: "btn btn-round btn-icon",
            isSpottedClass: "btn btn-round btn-icon"
        }
        this.reportFlash = this.reportFlash.bind(this);
        this.handleGoToMap = this.handleGoToMap.bind(this);
    }

    componentDidMount() {
        if(this.props.invader != null){
            // siUid
            this.setState({siUid: this.props.invader.id})

            // Name
            this.setState({name: this.props.invader.name})

            // Photo id
            this.setState({photoIDURL: this.props.invader.photoIDURL})

            // Status
            var status = this.props.invader.statusDelta
            if (status >=8) {
                this.setState({statusString: "Alive"})
                this.setState({statusPillClass: "badge badge-success badge-pill"})
            } else if (status >=6 && status <= 7) {
                this.setState({statusString: "Alive"})
                this.setState({statusPillClass: "badge badge-unflashable badge-pill"})
            } else if (status >=2 && status <= 5) {
                this.setState({statusString: "Dead"})
                this.setState({statusPillClass: "badge badge-danger badge-pill"})
            } else if (status == 1) {
                this.setState({statusString: "Dead"})
                this.setState({statusPillClass: "badge badge-definitivelyDead badge-pill"})
            } else {
                this.setState({statusString: "N.A."})
                this.setState({statusPillClass: "badge badge-unknown badge-pill"})
            }

            // Zone
            var zone = this.props.invader.zone

            // Points
            var points = this.props.invader.points
            if(points != "" && points != 0){
                points = points + " PTS"
            } else {
                points = "";
            }
            this.setState({ points: points });

            // Separator
            var zonePointsSeparator = " | ";
            if (zone=="" || points==""){
                var zonePointsSeparator = "";
            }
            this.setState({zonePointsAggregate : points + zonePointsSeparator + zone})

            // last Seen Ago
            if(this.props.invader.lastUpdateDate != ""){
                var today = new Date();
                var msInDay = 24 * 60 * 60 * 1000;
                var lastReportDateRaw = new Date(this.props.invader.lastUpdateDate);
                lastReportDateRaw.setHours(0,0,0,0);

                var lastReportDateAgo = (+today - +lastReportDateRaw)/msInDay
                lastReportDateAgo = Math.round(lastReportDateAgo);

                if(lastReportDateAgo < 30){
                    if(lastReportDateAgo == 0){
                        lastReportDateAgo = "today";
                    } else if(lastReportDateAgo == 1){
                        lastReportDateAgo = "1 day ago";
                    } else {
                        lastReportDateAgo = lastReportDateAgo + " days ago";
                    }
                } else if (lastReportDateAgo < 365) {
                    lastReportDateAgo = lastReportDateAgo/30;
                    lastReportDateAgo = Math.round(lastReportDateAgo);
                    if(lastReportDateAgo == 1){
                        lastReportDateAgo = "1 month ago";
                    } else {
                        lastReportDateAgo = lastReportDateAgo + " months ago";
                    }
                } else {
                    lastReportDateAgo = lastReportDateAgo/365;
                    lastReportDateAgo = Math.round(lastReportDateAgo);
                    if(lastReportDateAgo == 1){
                        lastReportDateAgo = "1 year ago";
                    } else {
                        lastReportDateAgo = lastReportDateAgo + " years ago";
                    }
                }
                lastReportDateAgo = "Updated " + lastReportDateAgo
            } else {
                lastReportDateAgo = ""
            }
            this.setState({lastUpdateAgo: lastReportDateAgo})

            // isFlashed
            if(this.props.invader.isFlashed) {
                this.setState({isFlashedClass: "btn btn-round btn-icon btn-active"})
            }

            // isSpotted
            if(this.props.invader.coordinates.latitude != 0) {
                this.setState({isSpottedClass: "btn btn-round btn-icon btn-active"})
            }
        }
    }

    async reportFlash(){
      try {

          const invader = this.state.invader
          invader.isFlashed = !this.state.isFlashed

          this.setState({
              invader: invader,
              isFlashed: !this.state.isFlashed
          }, async function () {

              const token = await this.props.getTokenSilently();

              var formdata = new FormData();
              formdata.append("siUid", this.props.invader.id);
              console.log("SI UID", this.props.invader.id);
              if(!this.state.isFlashed){
                  formdata.append("remove", "1");
              }

              const response = await fetch("https://secure.api.invadex.space/private/reportFlash",
                  {
                      method: 'POST',
                      headers: {
                          Authorization: `Bearer ${token}`
                      },
                      mode: 'cors',
                      cache: 'default',
                      body: formdata
                  }
              );

              const responseData = await response.json();

              if(responseData.error === 0){
                  console.log("No error")
                  if(this.state.invader.isFlashed){
                      this.setState({
                          isFlashedClass: "btn btn-round btn-icon btn-active",
                      })
                  } else {
                      this.setState({
                          isFlashedClass: "btn btn-round btn-icon",
                      })
                  }
              } else {
                  // Get back to previous state
                  const invader = this.state.invader
                  invader.isFlashed = !this.state.isFlashed
                  this.setState({
                      invader: invader,
                      isFlashed: !this.state.isFlashed
                  }, function() {
                      if(this.state.invader.isFlashed){
                          this.setState({
                              isFlashedClass: "btn btn-round btn-icon",
                          })
                      } else {
                          this.setState({
                              isFlashedClass: "btn btn-round btn-icon btn-active",
                          })
                      }
                  })
                  console.log("Error on server side", responseData.error)
              }
          });

        } catch (error) {
            console.error(error);
        }
    };

    handleGoToMap() {
        console.log("Go to map")
        if(this.props.invader.coordinates.latitude === 0){
            this.props.history.push("/map?localize=" + this.props.invader.id + "&zone=" + this.props.invader.zone);
        } else {
            this.props.history.push("/invader?siUid=" + this.props.invader.id);
        }
    }

    render() {
        const {invader} = this.state.invader;
        return (
            <Card className={this.state.style}>
                <Router history={history}>
                    <NavLink
                      to={"/invader?siUid=" + this.state.siUid}
                    >
                        <Card>
                            <Row>
                                <div>
                                    <img src={this.state.photoIDURL} class="img-fluid" alt=""/>
                                </div>
                                <div>
                                    <div class="card-body px-2">
                                        <div className="float-right status-pill-container align-right">
                                            <span className={this.state.statusPillClass}>{this.state.statusString}</span>
                                        </div>
                                        <div>
                                            <h4 class="card-title">{this.state.name}</h4>
                                        </div>
                                        <div className="float-right">
                                            <button
                                                type="button"
                                                rel="tooltip"
                                                class={this.state.isFlashedClass}
                                                onClick={e => {
                                                      e.preventDefault();
                                                      this.reportFlash();
                                                    }}
                                            >
                                                <i class="tim-icons icon-camera-18"></i>
                                            </button>
                                        </div>
                                        <h5>{this.state.zonePointsAggregate}</h5>
                                        <div className="float-right" style={{marginTop:-5}}>
                                            <button
                                                type="button"
                                                rel="tooltip"
                                                class={this.state.isSpottedClass}
                                                onClick={e => {
                                                      e.preventDefault();
                                                      this.handleGoToMap();
                                                    }}
                                            >
                                                <i class="tim-icons icon-pin"></i>
                                            </button>
                                        </div>
                                        <h6>{this.state.lastUpdateAgo}</h6>
                                    </div>
                                </div>
                            </Row>
                      </Card>
                  </NavLink>
                </Router>
            </Card>
        )
    }
}

export default InvaderCellCard;
