import LastUpdates from "views/LastUpdates.js";
import Map from "views/Map.js";
import CityGuides from "views/CityGuides.js";
import City from "views/City.js";
import InvaderProfile from "views/InvaderProfile";
import Search from "views/Search";
import User from "views/UserProfile";
import Live from "views/CityLive";

var routes = [
  {
    path: "lastupdates",
    name: "Last Updates",
    rtlName: "",
    icon: "tim-icons icon-sound-wave",
    component: LastUpdates,
    layout: "/"
  },
  {
    path: "map",
    name: "Map",
    rtlName: "",
    icon: "tim-icons icon-map-big",
    component: Map,
    layout: "/"
  },
  {
    path: "cityguide",
    name: "City Guides",
    rtlName: "",
    icon: "tim-icons icon-istanbul",
    component: CityGuides,
    layout: "/"
  },
  {
    path: "invader",
    name: "Invader",
    rtlName: "",
    icon: "tim-icons icon-single-02",
    component: InvaderProfile,
    layout: "/",
    invisible: true
  },
  {
    path: "city",
    name: "City",
    rtlName: "",
    icon: "tim-icons icon-istanbul",
    component: City,
    layout: "/",
    invisible: true
  },
  {
    path: "search",
    name: "Search",
    rtlName: "",
    icon: "tim-icons icon-zoom-split",
    component: Search,
    layout: "/",
    invisible: true
  },
  {
    path: "user",
    name: "User",
    rtlName: "",
    icon: "tim-icons icon-zoom-split",
    component: User,
    layout: "/",
    invisible: true
  },
  {
    path: "live",
    name: "Live",
    rtlName: "",
    icon: "tim-icons icon-sound-wave",
    component: Live,
    layout: "/",
    invisible: true
  }
];
export default routes;
