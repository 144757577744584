import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col
} from "reactstrap";
import Loading from "components/Loading"

import TimelineCard from "components/Cards/Timeline"

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.getLastUpdates = this.getLastUpdates.bind(this);
    this.state = {
        visible: true,
        cards: <Loading />
    };
  }

  async getLastUpdates(){
    try {

      const token = await this.props.getTokenSilently();

      const response = await fetch("https://secure.api.invadex.space/private/delta/getLastUpdates",
          {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            mode: 'cors',
            cache: 'default'
          }
      );

      const responseData = await response.json();

      let cards = responseData.map((invader, i) => {
          return(
              <TimelineCard {...this.props} i={i} invader={invader} />
          )
      })

      this.setState({ cards: cards });

    } catch (error) {
      console.error(error);
    }
  };

  async getAccount(){
      try {
          const token = await this.props.getTokenSilently();

          var lang = navigator.language || navigator.userLanguage;

          var formdata = new FormData();
          formdata.append("name", this.props.user.nickname);
          formdata.append("email", this.props.user.email);
          formdata.append("uuid", "");
          formdata.append("systemVersion", window.navigator.userAgent);
          formdata.append("model", "");
          formdata.append("systemLanguage", lang);

          const response = await fetch("https://secure.api.invadex.space/private/getAccount",
              {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                mode: 'cors',
                cache: 'default',
                body: formdata
              }
          );

          const responseData = await response.json();

          // Role String
          var roleString = "Space Hunter"
          if(responseData === 1){
              roleString = "Admin"
          }
          if(responseData === 2){
              roleString = "VIP Space Hunter"
          }
          if(responseData === 3){
              roleString = "Space Hunter"
          }
          if(responseData === 4){
              roleString = "Space Gazer"
          }
          if(responseData === 5){
              roleString = "Space Doctor"
          }

          // Flashed String
          var flashCountString = responseData.flashCount + " invaders flashed"
          if(responseData.flashCount < 2){
              flashCountString = responseData.flashCount + " invader flashed"
          }

          // Points
          var totalPointsString = responseData.totalPoints + " PTS"

          this.setState({
              publicName: responseData.name,
              flashCount: responseData.flashCount,
              flashCountString: flashCountString,
              notifLastUpdates: responseData.notifLastUpdates,
              role: responseData.role,
              roleString: roleString,
              totalPoints: responseData.totalPoints,
              totalPointsString: totalPointsString,
              totalCities: responseData.totalCities,
              uuidFI: responseData.uuidFI
          })

      } catch (error) {
          console.error(error);
      }
  };

  componentDidMount() {
      this.getAccount() // Create user if it does not exists
      this.getLastUpdates()
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs="12">
                <Card className="card-timeline card-plain card">
                    <CardBody>
                        <ul className="timeline">
                            {this.state.cards}
                        </ul>
                    </CardBody>
                </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
