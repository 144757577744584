import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardTitle,
  Media
} from "reactstrap";

import ExcelPicture from 'assets/img/excel.png';
import MyMapsPicture from 'assets/img/mymaps.png';

class User extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            publicName: "",
            flashCount: 0,
            flashCountString: "",
            points: 0,
            notifLastUpdates: 0,
            role: 3,
            roleString: "",
            totalPoints: 0,
            totalPointsString: "",
            totalCities: 0,
            uuidFI: "",
            alert: null,
            mapURL: "",
            mapName: "",
            selectedFlashesFile: null,
            isImportingFlashes: false,
            selectedKMLFile: null,
            isImportingKML: false
        }
    }

    componentDidMount() {
        this.getAccount()
    }

    onSelectCSVHandler=event=>{
        this.setState({
            selectedFlashesFile: event.target.files[0],
            loaded: 0,
        })
    }

    onSelectKMLHandler=event=>{
        this.setState({
            selectedKMLFile: event.target.files[0],
            loaded: 0,
        })
    }

    onClickUploadCSVHandler = () => {
        this.importFlashes()
    }

    onClickUploadKMLHandler = () => {
        this.importKML()
    }

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Good job!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    btnSize=""
                >
                    Your export has been sent to your email address
                </ReactBSAlert>
            )
        });
    };

    successImportAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Good job!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    btnSize=""
                >
                    Your import was successful
                </ReactBSAlert>
            )
        });
    };

    errorAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Ouuuups"
                    onConfirm={() => this.hideAlert()}
                    showConfirm={false}
                >
                    An unknown error has occured.
                </ReactBSAlert>
            )
        });
        setTimeout(this.hideAlert, 3000);
    };

    async getAccount(){
      try {
        const token = await this.props.getTokenSilently();

        var lang = navigator.language || navigator.userLanguage;

        var formdata = new FormData();
        formdata.append("name", this.props.user.nickname);
        formdata.append("email", this.props.user.email);
        formdata.append("uuid", "");
        formdata.append("systemVersion", window.navigator.userAgent);
        formdata.append("model", "");
        formdata.append("systemLanguage", lang);

        const response = await fetch("https://secure.api.invadex.space/private/getAccount",
            {
              method: 'POST',
              headers: {
                  Authorization: `Bearer ${token}`
              },
              mode: 'cors',
              cache: 'default',
              body: formdata
            }
        );

        const responseData = await response.json();

        // Role String
        var roleString = "Space Hunter"
        if(responseData === 1){
            roleString = "Admin"
        }
        if(responseData === 2){
            roleString = "VIP Space Hunter"
        }
        if(responseData === 3){
            roleString = "Space Hunter"
        }
        if(responseData === 4){
            roleString = "Space Gazer"
        }
        if(responseData === 5){
            roleString = "Space Doctor"
        }

        // Flashed String
        var flashCountString = responseData.flashCount + " invaders flashed"
        if(responseData.flashCount < 2){
            flashCountString = responseData.flashCount + " invader flashed"
        }

        // Points
        var totalPointsString = responseData.totalPoints + " PTS"

        this.setState({
            publicName: responseData.name,
            flashCount: responseData.flashCount,
            flashCountString: flashCountString,
            notifLastUpdates: responseData.notifLastUpdates,
            role: responseData.role,
            roleString: roleString,
            totalPoints: responseData.totalPoints,
            totalPointsString: totalPointsString,
            totalCities: responseData.totalCities,
            uuidFI: responseData.uuidFI
        })

      } catch (error) {
        console.error(error);
      }
    };

    async exportToMail(type){
      try {
          const token = await this.props.getTokenSilently();

          var formdata = new FormData();
          formdata.append("type", "map");

          const response = await fetch("https://secure.api.invadex.space/private/exportByEmail",
              {
                  method: 'POST',
                  headers: {
                      Authorization: `Bearer ${token}`
                  },
                  mode: 'cors',
                  cache: 'default',
                  body: formdata
              }
          );

          const responseData = await response.json();

          console.log("Response", responseData)

          if(responseData.error === 0){
              this.successAlert()
          } else {
              this.errorAlert()
              console.log("Error with Export")
          }

        } catch (error) {
            this.errorAlert()
            console.error(error);
        }
    };

    async importMap(){
      try {
          const token = await this.props.getTokenSilently();

          var formdata = new FormData();
          formdata.append("mapName", this.state.mapName);
          formdata.append("mapURL", this.state.mapURL);

          const response = await fetch("https://secure.api.invadex.space/private/syncMyMaps",
              {
                  method: 'POST',
                  headers: {
                      Authorization: `Bearer ${token}`
                  },
                  mode: 'cors',
                  cache: 'default',
                  body: formdata
              }
          );

          const responseData = await response.json();

          console.log("Response", responseData)

          if(responseData.error === 0){
              this.successImportAlert()
          } else {
              this.errorAlert()
              console.log("Error with Import")
          }

        } catch (error) {
            this.errorAlert()
            console.error(error);
        }
    };

    async importFlashes(){
        this.setState({ isImportingFlashes: true })
      try {
          const token = await this.props.getTokenSilently();

          var formdata = new FormData();
          formdata.append('file', this.state.selectedFlashesFile)

          const response = await fetch("https://secure.api.invadex.space/private/importFlashes",
              {
                  method: 'POST',
                  headers: {
                      Authorization: `Bearer ${token}`
                  },
                  mode: 'cors',
                  cache: 'default',
                  body: formdata
              }
          );

          const responseData = await response.json();

          console.log("Response", responseData)

          if(responseData.error === 0){
              this.successImportAlert()
              this.setState({
                  isImportingFlashes: false,
                  selectedFlashesFile: null
              })
          } else {
              this.errorAlert()
              console.log("Error with Import")
              this.setState({
                  isImportingFlashes: false,
                  selectedFlashesFile: null
              })
          }

        } catch (error) {
            this.errorAlert()
            console.error(error);
            this.setState({
                isImportingFlashes: false,
                selectedFlashesFile: null
            })
        }
    };

    async importKML(){
        this.setState({ isImportingKML: true })
      try {
          const token = await this.props.getTokenSilently();

          var formdata = new FormData();
          formdata.append('file', this.state.selectedKMLFile)

          const response = await fetch("https://secure.api.invadex.space/private/importKML",
              {
                  method: 'POST',
                  headers: {
                      Authorization: `Bearer ${token}`
                  },
                  mode: 'cors',
                  cache: 'default',
                  body: formdata
              }
          );

          const responseData = await response.json();

          console.log("Response", responseData)

          if(responseData.error === 0){
              this.successAlert()
              this.setState({
                  isImportingKML: false,
                  selectedKMLFile: null
              })
          } else {
              this.errorAlert()
              console.log("Error with Import")
              this.setState({
                  isImportingKML: false,
                  selectedKMLFile: null
              })
          }

        } catch (error) {
            this.errorAlert()
            console.error(error);
            this.setState({
                isImportingKML: false,
                selectedKMLFile: null
            })
        }
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

  render() {
    return (
      <>
        <div className="content">
        {this.state.alert}
          <Row>
              <Col md="4">
                <Card className="card-user">
                  <CardBody>
                    <CardText />
                    <div className="author">
                      <div className="block block-one" />
                      <div className="block block-two" />
                      <div className="block block-three" />
                      <div className="block block-four" />
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="avatar"
                          src={this.props.user.picture}
                        />
                        <h3 className="title">{this.props.user.nickname}</h3>
                      </a>
                      <p className="description">
                        {this.state.totalPointsString}
                      </p>
                    </div>
                    <div className="description text-center">
                        {this.state.flashCountString}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Profile</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Email</label>
                          <Input
                            defaultValue={this.props.user.email}
                            disabled
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Public Name (as it appears to others in Invadex)</label>
                            <Input
                              defaultValue={this.state.publicName}
                              disabled
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Password</label>
                            <Input
                              defaultValue="••••••••"
                              disabled
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Role</label>
                            <Input
                              defaultValue={this.state.roleString}
                              disabled
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="title">Export Options</h5>
                </CardHeader>
                <CardBody>
                    This will send you an email with attached file of your choice <br/>
                    <Button
                        className="btn-fill"
                        color="info"
                        onClick={e => {
                            e.preventDefault();
                            this.exportToMail("flashes");
                        }}
                    >
                        Export Flashes
                    </Button>
                    <Button
                        className="btn-fill"
                        color="info"
                        onClick={e => {
                            e.preventDefault();
                            this.exportToMail("map");
                        }}
                    >
                      Export Map
                    </Button>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="title">Import Flashes from a CSV file (MS Excel, Numbers, Google Sheets...)</h5>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col sm="12">
                            <Media>
                                <Media left top href="#" style={{marginRight: "20px", marginBottom: "20px"}}>
                                  <Media object src={ExcelPicture} width="200px" alt="Excel" />
                                </Media>
                                <Media body>
                                  <Media heading>
                                    Prepare your file as follow
                                  </Media>
                                  <li>Optional : <a href="https://ressources.invadex.space/imports/FlashList.csv" download>Download the example file</a></li>
                                  <li>Fill the firt column with Invaders name (e.g. PA_13, AMS23, PA-0002...).</li>
                                  <li>No header</li>
                                  <li>Important, save the file as "CSV File (separated with semicolon)"</li>
                                  <li>Invaders already marked as flashed in Invadex will be ignored</li>
                                  <br/>
                                  <Input type="file" name="file" onChange={this.onSelectCSVHandler}/>
                                  <br/><br/>
                                </Media>
                            </Media>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Button
                                className="btn-fill"
                                color="info"
                                onClick={e => {
                                    e.preventDefault();
                                    this.onClickUploadCSVHandler();
                                }}
                                disabled={this.state.isImportingFlashes}
                            >
                              {this.state.isImportingFlashes ? 'Processing...' : 'Import'}
                            </Button>
                            {this.state.isImportingFlashes ? ' Please wait. This might take up to 5 minutes.' : ''}
                        </Col>
                    </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="title">Import a Map from a KML file</h5>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col sm="12">
                            <Media>
                                <Media left top href="#" style={{marginRight: "20px", marginBottom: "20px"}}>
                                  <Media object src={MyMapsPicture} width="200px" alt="MyMaps" />
                                </Media>
                                <Media body>
                                  <Media heading>
                                    Prepare your file as follow
                                  </Media>
                                  <li>Nameholders of positions must start with Invader names (e.g. PA01, PA-0045, PA_1413, NY2, ...)</li>
                                  <li>No more requirements</li>
                                  <li>Any additional data will be ignored</li>
                                  <li>Invaders already localised in Invadex will be ignored</li>
                                  <br/>
                                  <Input type="file" name="file" onChange={this.onSelectKMLHandler}/>
                                  <br/><br/>
                                </Media>
                            </Media>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Button
                                className="btn-fill"
                                color="info"
                                onClick={e => {
                                    e.preventDefault();
                                    this.onClickUploadKMLHandler();
                                }}
                                disabled={this.state.isImportingKML}
                            >
                              {this.state.isImportingKML ? 'Processing...' : 'Import'}
                            </Button>
                            {this.state.isImportingKML ? ' Please wait. This might take up to 5 minutes.' : ''}
                        </Col>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default User;
