import React from "react";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import InvaderCellCard from "components/Cards/InvaderCellCard";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Row } from "reactstrap";
import Loading from "components/Loading"
import NotificationAlert from 'react-notification-alert';

const mapStyles = { width: '100%', height: 'calc(100% - 66px)', top:'66px' };

let mapOptions = {
  scrollwheel: false,
  zoomControlOptions: {
      //   position: 'RIGHT_CENTER',    // as long as this is not set it works
      style: 'SMALL'
  },
  mapTypeControlOptions: {
      position: 'BOTTOM_RIGHT'     // this makes the map type control disappear
  },
  draggable: false,
  rotateControl: false,
  scaleControl: false,
  streetViewControl: false,
  panControl: false,
};

const invader = {
    "id": 0,
    "name": "Babar",
    "cityCode": "",
    "number": "",
    "points": "",
    "status": "",
    "zone": "",
    "address": "",
    "invasionDate": "",
    "lastReactivationDate": "",
    "invaderColor": "",
    "backgroundColor": "",
    "shape": "",
    "comment": {
        "text": "",
        "source": ""
    },
    "photoIDURL": "https://ressources.invadex.space/images/no-image.png",
    "photoIDSource": "",
    "photosURLList": "",
    "photosSourcesList": "",
    "lastUpdateDate": "",
    "lastUpdateSource": "",
    "reports": {
        "lastReportStatus": "",
        "lastReportUser": "",
        "lastReportDate": "",
        "beforeLastReportStatus": "",
        "beforeLastReportUser": "",
        "beforeLastReportDate": "",
        "antepenultimateReportStatus": "",
        "antepenultimateReportUser": "",
        "antepenultimateReportDate": ""
    },
    "coordinates": {
        "latitude": "",
        "longitude": ""
    },
    "isFlashed": false,
    "reportCount": ""
}

var errorAlertOptions = {
    place: 'tc',
    message: (
        <div>
            <div>
                An unknown error has occured
            </div>
        </div>
    ),
    type: "danger",
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 7
}

class FullScreenMap extends React.Component {
    constructor(props) {
        super(props);
        this.getSpots = this.getSpots.bind(this);
        this.state = {
            invaders: [],
            invadersToDisplay: [],
            fullInvadersList: [],
            selectedInvader: invader,
            modalMini: false,
            modalMiniConfirmLocalize: false,
            modalBody: "",
            modalBodyConfirmLocalize: "",
            loading: <Loading />,
            mapInitialCenter: {lat: 48.861473, lng: 2.334090},
            mapCenter: {lat: 48.861473, lng: 2.334090},
            mapZoom: 14,
            shouldCenterBool: false,
            shouldCenterLatitude: 0,
            shouldCenterLongitude: 0,
            localizeMode: false,
            localizeLatitude: 0,
            localizeLongitude: 0,
            userLatitude: null,
            userLongitude: null,
            userMarker: null,
            streetViewControl: true,
            filtersShow: false,
            flashedFilter: 'all',
            statusFilter: 'All',
            spottedFilter: 'all',
            pointsFilter: 'All',
            currentLatLng: {
                lat: 0,
                lng: 0
            },
        }
        this.toggleModalFilters = this.toggleModalFilters.bind(this);
    }

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
              showingInfoWindow: false,
              activeMarker: null
            });
        }
    };

    toggleModalMini = (marker) => {
        this.setState({
            modalMini: !this.state.modalMini
        });
        if(marker.invader != null){
            this.setState({ selectedInvader : marker.invader });
            this.setState({ modalBody: "" })
            this.getInvader();
        }
    };

    toggleModalMiniConfirmLocalize() {
        this.setState({
            modalMiniConfirmLocalize: !this.state.modalMiniConfirmLocalize
        });

        this.setState({ markers: [] })
    };

    handleGoToDetails = () => {
        this.props.history.push("/invader?siUid=" + this.state.selectedInvader.id);
    }

    async getSpots(){
        this.setState({
            markers: [],
            flashedFilter: 'all',
            statusFilter: 'All',
            spottedFilter: 'all',
            pointsFilter: 'All',
            filtersApplied: ""
        })

        try {
            const token = await this.props.getTokenSilently();

            const response = await fetch("https://secure.api.invadex.space/private/getSpots",
                {
                  method: 'POST',
                  headers: {
                      Authorization: `Bearer ${token}`
                  },
                  mode: 'cors',
                  cache: 'default'
                }
            );

            const responseData = await response.json();

            this.setState({ fullInvadersList: responseData })
            this.setState({ invadersToDisplay: responseData })

            let markers = this.prepareMarkers(responseData)

            this.setState({
                markers: markers,
                resultsCount: responseData.length,
            });
            this.setState({ loading: "" });

        } catch (error) {
            this.showErrorAlert();
            console.error(error);
        }
    };

    prepareMarkers(invaders) {
        let markers = invaders.map((invader, i) => {
            var markerImage = {
                url: require('assets/img/Map_Spot_Grey@2x.png'),
                size: {width: 40, height: 50},
                anchor: {x: 10, y: 25},
                scaledSize: {width: 20, height: 25}
            }
            if(invader.isFlashed){
                markerImage = {
                    url: require('assets/img/Map_Spot_Invadex@2x.png'),
                    size: {width: 40, height: 50},
                    anchor: {x: 10, y: 25},
                    scaledSize: {width: 20, height: 25}
                }
            } else if (invader.statusDelta >= 8) {
                markerImage = {
                    url: require('assets/img/Map_Spot_Green@2x.png'),
                    size: {width: 40, height: 50},
                    anchor: {x: 10, y: 25},
                    scaledSize: {width: 20, height: 25}
                }
            } else if (invader.statusDelta >= 6 && invader.status <= 7) {
                markerImage = {
                    url: require('assets/img/Map_Spot_Gold@2x.png'),
                    size: {width: 40, height: 50},
                    anchor: {x: 10, y: 25},
                    scaledSize: {width: 20, height: 25}
                }
            } else if (invader.statusDelta >= 2 && invader.status <= 5) {
                markerImage = {
                    url: require('assets/img/Map_Spot_Grey@2x.png'),
                    size: {width: 40, height: 50},
                    anchor: {x: 10, y: 25},
                    scaledSize: {width: 20, height: 25}
                }
            } else if (invader.statusDelta == 1) {
                markerImage = {
                    url: require('assets/img/Map_Spot_Black@2x.png'),
                    size: {width: 40, height: 50},
                    anchor: {x: 10, y: 25},
                    scaledSize: {width: 20, height: 25}
                }
            } else if (invader.statusDelta == 0) {
                markerImage = {
                    url: require('assets/img/Map_Spot_Grey@2x.png'),
                    size: {width: 40, height: 50},
                    anchor: {x: 10, y: 25},
                    scaledSize: {width: 20, height: 25}
                }
            }
            return (
                <Marker
                    icon={markerImage}
                    key={i}
                    title={invader.name}
                    name={invader.name}
                    invader={invader}
                    position={{lat: invader.coordinates.latitude, lng: invader.coordinates.longitude}}
                    onClick={this.toggleModalMini.bind(this)}
                />
            );
        })

        return markers
    }

    async getInvader(){
          try {
            const token = await this.props.getTokenSilently();

            var formdata = new FormData();
            formdata.append("siUid", this.state.selectedInvader.id);

            const response = await fetch("https://secure.api.invadex.space/private/getInvader",
                {
                  method: 'POST',
                  headers: {
                      Authorization: `Bearer ${token}`
                  },
                  mode: 'cors',
                  cache: 'default',
                  body: formdata
                }
            );

            const responseData = await response.json();

            const modalBody = <InvaderCellCard invader={responseData}/>

            if(this.state.localizeMode){
                this.setState({ modalBodyConfirmLocalize: modalBody })
            } else {
                this.setState({ modalBody: modalBody })
            }

        } catch (error) {
          this.showErrorAlert();
          console.error(error);
        }
    }

    async reportSpot(){
          try {
            const token = await this.props.getTokenSilently();

            var formdata = new FormData();
            formdata.append("siUid", this.state.selectedInvader.id);
            formdata.append("latitude", this.state.localizeLatitude);
            formdata.append("longitude", this.state.localizeLongitude);

            const response = await fetch("https://secure.api.invadex.space/private/reportSpot",
                {
                  method: 'POST',
                  headers: {
                      Authorization: `Bearer ${token}`
                  },
                  mode: 'cors',
                  cache: 'default',
                  body: formdata
                }
            );

            const responseData = await response.json();

            if (responseData.error == 0) {
                this.props.history.goBack();
            } else {
                this.showErrorAlert();
            }
        } catch (error) {
            this.showErrorAlert();
            console.error(error);
        }
    }

    componentDidMount() {

        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const center = params.get('center');
        const latitude = parseFloat(params.get('latitude'));
        const longitude = parseFloat(params.get('longitude'));
        const siToLocalize = params.get('localize');
        const zoneToLocalize = params.get('zone');

        if(center != "" && center != null && latitude != "" && latitude != 0 && longitude != "" && longitude != 0){

            this.setState({ shouldCenterBool: true })
            this.setState({ shouldCenterInvader: center })

            this.setState({ shouldCenterLatitude: latitude, shouldCenterLongitude: longitude})

            this.setState({ mapCenter : {lat: latitude, lng: longitude} });
            this.setState({ mapZoom : 18});

            this.getSpots()

        } else if (siToLocalize != null && siToLocalize != "" && siToLocalize != 0 && zoneToLocalize != null && zoneToLocalize != "" && zoneToLocalize != 0) {

            this.setState({ loading: "" });
            var selectedInvader = this.state.selectedInvader

            selectedInvader.id = siToLocalize
            selectedInvader.zone = zoneToLocalize

            this.setState({
                selectedInvader: selectedInvader,
                localizeMode: true
            })

            if(this.state.userLatitude != null && this.state.userLongitude != null){
                this.setState({ shouldCenterLatitude: this.state.userLatitude });
                this.setState({ shouldCenterLongitude: this.state.userLongitude });
                this.setState({ mapCenter: {lat: this.state.userLatitude, lng: this.state.userLongitude} });
            }

            this.setState({ mapZoom : 12});

        } else {

            if(this.state.userLatitude != null && this.state.userLongitude != null){
                this.setState({ shouldCenterLatitude: this.state.userLatitude });
                this.setState({ shouldCenterLongitude: this.state.userLongitude });
            }

            this.getSpots()
        }

        this.getCurrentLocation()
    }

    showErrorAlert(){
        this.refs.notify.notificationAlert(errorAlertOptions);
    }

    addMarker(lat, lng){
        let marker =  <Marker
                        icon={{
                            url: require('assets/img/Map_Spot_Green@2x.png'),
                            size: {width: 40, height: 50},
                            anchor: {x: 10, y: 25},
                            scaledSize: {width: 20, height: 25}
                        }}
                        key="1289731827482"
                        title=""
                        name=""
                        invader=""
                        position={{lat: lat, lng: lng}}
                      />
        this.setState({ markers: [marker] })
    }

    removeMarkers(){
        this.setState({ markers: [] })
    }

    handleMapClick(mapProps, map, clickEvent) {
        const { latLng } = clickEvent;
        const lat = latLng.lat();
        const lng = latLng.lng();

        if (this.state.localizeMode) {
            this.toggleModalMiniConfirmLocalize()
            this.addMarker(lat, lng)
            this.setState({
                localizeLatitude: lat,
                localizeLongitude: lng,
                shouldCenterLatitude: lat,
                shouldCenterLongitude: lng
            })

            this.getInvader()
        }
    }

    toggleModalFilters() {
        this.setState({
            filtersShow: !this.state.filtersShow
        })
    }

    handleFiltersChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, function() {
          this.applyFilter();
        });
    };

    applyFilter() {
        var fiteredInvaders = this.state.fullInvadersList
        var filtersApplied = 0

        // Flashed
        if(this.state.flashedFilter === 'all'){
            fiteredInvaders = this.state.fullInvadersList
        }
        if(this.state.flashedFilter === 'flashed'){
            fiteredInvaders = this.state.fullInvadersList.filter(invader => invader.isFlashed)
            filtersApplied += 1
        }
        if(this.state.flashedFilter === 'notflashed'){
            fiteredInvaders = this.state.fullInvadersList.filter(invader => !invader.isFlashed)
            filtersApplied += 1
        }

        // Status
        if(this.state.statusFilter === 'Alive'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.statusDelta >= 8)
            filtersApplied += 1
        }
        if(this.state.statusFilter === 'Unflashable'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.statusDelta >= 6 && invader.statusDelta <= 7)
            filtersApplied += 1
        }
        if(this.state.statusFilter === 'Dead'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.statusDelta >= 2 && invader.statusDelta <= 5)
            filtersApplied += 1
        }
        if(this.state.statusFilter === 'Definitively Dead'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.statusDelta === 1)
            filtersApplied += 1
        }
        if(this.state.statusFilter === 'Unknown'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.statusDelta === 0)
            filtersApplied += 1
        }

        // Spotted
        if(this.state.spottedFilter === 'spotted'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.coordinates.latitude === 1)
            filtersApplied += 1
        }
        if(this.state.spottedFilter === 'notspotted'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.coordinates.latitude === 0)
            filtersApplied += 1
        }

        // Points
        if(this.state.pointsFilter === '10 PTS'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.points === 10)
            filtersApplied += 1
        }
        if(this.state.pointsFilter === '20 PTS'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.points === 20)
            filtersApplied += 1
        }
        if(this.state.pointsFilter === '30 PTS'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.points === 30)
            filtersApplied += 1
        }
        if(this.state.pointsFilter === '40 PTS'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.points === 40)
            filtersApplied += 1
        }
        if(this.state.pointsFilter === '50 PTS'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.points === 50)
            filtersApplied += 1
        }
        if(this.state.pointsFilter === '100 PTS'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.points === 100)
            filtersApplied += 1
        }
        if(this.state.pointsFilter === 'Unknown'){
            fiteredInvaders = fiteredInvaders.filter(invader => invader.points === 0)
            filtersApplied += 1
        }

        if(filtersApplied > 0){
            if(filtersApplied == 1){
                this.setState({ filtersApplied: "(1 filter)" })
            } else {
                this.setState({ filtersApplied: "(" + filtersApplied + " filters)" })
            }
        } else {
            this.setState({ filtersApplied: "" })
        }

        this.setState({ invaders: fiteredInvaders }, function() {
            this.setState({
                markers: this.prepareMarkers(fiteredInvaders),
                resultsCount: fiteredInvaders.length,
            })
        })
    }

    resetFilters() {
        this.setState({
            flashedFilter: 'all',
            statusFilter: 'All',
            spottedFilter: 'all',
            pointsFilter: 'All'
        }, function() {
          this.applyFilter();
          this.toggleModalFilters()
        });
    }

    getCurrentLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.watchPosition(
            position => {
              this.setState({
                  userLatitude: position.coords.latitude,
                  userLongitude: position.coords.longitude
              }, function() {
                  this.generateUserMarker()
              })
            }
          )
      } else {
          console.log("Browser has no geoloc")
      }
    }

    generateUserMarker(){
        if(this.state.userLatitude != null && this.state.userLatitude != 0 && this.state.userLatitude != ""){
            let userMarker =  <Marker
                                  icon={{
                                      url: require('assets/img/userLocationDot.png'),
                                      size: {width: 70, height: 70},
                                      anchor: {x: 17, y: 17},
                                      scaledSize: {width: 35, height: 35}
                                  }}
                                  key="1289731827482"
                                  title=""
                                  name=""
                                  invader=""
                                  position={{lat: this.state.userLatitude, lng: this.state.userLongitude}}
                              />
            this.setState({ userMarker: [userMarker] })
        }
    }

    render() {
        return (
        <>
            <NotificationAlert ref="notify" />
            <div id="map">

                <Map
                    google={this.props.google}
                    onClick={this.handleMapClick.bind(this)}
                    onReady={this.mapIsReady}
                    zoom={this.state.mapZoom}
                    style={mapStyles}
                    initialCenter={this.state.mapInitialCenter}
                    center={this.state.mapCenter}
                    streetViewControl={this.state.streetViewControl}
                    zoomControl={true}
                    mapTypeControl={false}
                    scaleControl={false}
                    rotateControl={false}
                    fullscreenControl={false}
                    currentLocation={this.state.currentLatLng}

                    {...this.props}
                >
                    {this.state.markers}
                    {this.state.userMarker}
                </Map>

                <Modal
                    size="lg"
                    isOpen={this.state.filtersShow}
                    toggle={this.toggleModalFilters}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <ModalHeader>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                            onClick={(el, state) => this.toggleModalFilters(el, state)}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        <b>Filters</b> | {this.state.resultsCount}
                    </ModalHeader>
                    <ModalBody>
                        <p><Label for="flashed"><b>FLASHED</b></Label></p>
                        <FormGroup check className="form-check-radio form-check-inline">
                            <Label className="form-check-label">
                                <Input type="radio" name="flashedFilter" id="flashed-all" value="all" onChange={this.handleFiltersChange} defaultChecked={ this.state.flashedFilter === "all" ? true : false } />All<span className="form-check-sign"></span>
                            </Label>
                            <Label className="form-check-label">
                                <Input type="radio" name="flashedFilter" id="flashed-flashed" value="flashed" onChange={this.handleFiltersChange} defaultChecked={ this.state.flashedFilter === "flashed" ? true : false } />Yes<span className="form-check-sign"></span>
                            </Label>
                            <Label className="form-check-label">
                                <Input type="radio" name="flashedFilter" id="flashed-notflashed" value="notflashed" onChange={this.handleFiltersChange} defaultChecked={ this.state.flashedFilter === "notflashed" ? true : false } />No<span className="form-check-sign"></span>
                            </Label>
                        </FormGroup>
                        <br/><br/>
                        <p><Label for="statusFilter"><b>INVADER STATUS</b></Label></p>
                        <FormGroup>
                            <Input type="select" name="statusFilter" id="statusFilter" onChange={this.handleFiltersChange}  defaultValue={this.state.statusFilter}>
                                <option>All</option>
                                <option>Alive</option>
                                <option>Unflashable</option>
                                <option>Dead</option>
                                <option>Definitively Dead</option>
                                <option>Unknown</option>
                            </Input>
                        </FormGroup>
                        <br/>
                        <p><Label for="pointsFilter"><b>POINTS</b></Label></p>
                        <FormGroup>
                            <Input type="select" name="pointsFilter" id="pointsFilter" onChange={this.handleFiltersChange} defaultValue={this.state.pointsFilter}>
                                <option>All</option>
                                <option>10 PTS</option>
                                <option>20 PTS</option>
                                <option>30 PTS</option>
                                <option>40 PTS</option>
                                <option>50 PTS</option>
                                <option>100 PTS</option>
                                <option>Unknown</option>
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="default"
                            onClick={(el, state) => this.resetFilters(el, state)}
                        >
                            Reset
                        </Button>
                        <Button
                            color="default"
                            onClick={(el, state) => this.toggleModalFilters(el, state)}
                        >
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal
                    modalClassName="modal-mini modal-default"
                    isOpen={this.state.modalMini}
                    toggle={this.toggleModalMini}
                  >
                    <ModalBody className="text-center">
                        {this.state.modalBody}
                    </ModalBody>
                </Modal>

                <Modal
                    modalClassName="modal-mini modal-default"
                    isOpen={this.state.modalMiniConfirmLocalize}
                    toggle={this.toggleModalMiniConfirmLocalize.bind(this)}
                  >
                    <ModalBody className="text-center">
                        {this.state.modalBodyConfirmLocalize}
                    </ModalBody>
                    <ModalFooter>
                            <FormGroup>
                              <label>Coordinates</label>
                              <Input value={this.state.localizeLatitude + ", " + this.state.localizeLongitude} type="text" />
                            </FormGroup>
                            <Button
                                className="btn-success"
                                color="link"
                                type="button"
                                onClick={this.reportSpot.bind(this)}
                            >
                                Validate
                            </Button>
                    </ModalFooter>
                </Modal>

                <Button
                    {...this.props}
                    color="default"
                    onClick={this.toggleModalFilters}
                    className="btn btn-round btn-icon btn-active"
                    style={{float: 'right', marginTop: '80px', marginRight: '8px'}}
                >
                    <i className={"tim-icons icon-bullet-list-67"}></i>
                </Button>

                {this.state.loading}
            </div>
        </>
        );
    }
}

export default GoogleApiWrapper({ apiKey: 'AIzaSyAoTDumP2gApfZ5NKZhkpVurZkHG3XE5-I' })(FullScreenMap);
