import React from "react";
import { Card, CardBody } from "reactstrap";

class LiveCellCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            live: this.props,
        }
    }

    render() {
        const {live} = this.state.live;
        return (
                <Card className="card-live">
                  <div className="card-image">
                      <img
                        alt={live.player}
                        className="img rounded"
                        src={live.imgURL}
                      />
                  </div>
                  <CardBody>
                      <h3 className="live-title">{live.player}</h3>
                      <div className="live-subtitle">{live.timeStamp}</div>
                  </CardBody>
                </Card>
        )
    }
}

export default LiveCellCard;
