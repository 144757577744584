import React from "react";
import InvaderCellCard from "components/Cards/InvaderCellCard"

class TimelineCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            floatStyle: "",
            liStyle: "",
            timelineBadgeStyle: "",
            timelineBadgeIcon: ""
        }
    }

    componentDidMount() {
        var liStyle = ""
        var floatStyle = "invaderCell-right"
        if(this.props.i%2 === 0) {
            liStyle = "timeline-inverted";
            floatStyle = "invaderCell-left"
        }
        this.setState({liStyle: liStyle})
        this.setState({floatStyle: floatStyle})

        // Status
        var timelineBadgeStyle = "timeline-badge success align-right";
        var timelineBadgeIcon = "tim-icons icon-check-2";
        if (this.props.invader.status < 5) {
          timelineBadgeStyle = "timeline-badge danger align-right";
          timelineBadgeIcon = "tim-icons icon-simple-remove";
        }
        this.setState({ timelineBadgeStyle: timelineBadgeStyle });
        this.setState({ timelineBadgeIcon: timelineBadgeIcon });
    }

    render() {
      return (
          <>
          <li className={this.state.liStyle}>
              <div className={this.state.timelineBadgeStyle}>
                  <i className={this.state.timelineBadgeIcon}></i>
              </div>
              <div className="timeline-panel">
                    <InvaderCellCard {...this.props} invader={this.props.invader} style={this.state.floatStyle} />
              </div>
          </li>
          </>
      );
    }
}

export default TimelineCard;
