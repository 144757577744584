import React from "react";
import { Router, NavLink } from "react-router-dom";
import Switch from 'react-bootstrap-switch';
import NotificationAlert from 'react-notification-alert';
import history from "utils/history";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Table
} from "reactstrap";

const invader = {
    "id": 0,
    "name": "",
    "cityCode": "",
    "number": 0,
    "points": 0,
    "status": 0,
    "zone": "",
    "address": "",
    "invasionDate": "",
    "lastReactivationDate": "",
    "invaderColor": "",
    "backgroundColor": "",
    "shape": "",
    "comment": {
        "text": "",
        "source": ""
    },
    "photoIDURL": "",
    "photoIDSource": "",
    "photosURLList": "",
    "photosSourcesList": "",
    "lastUpdateDate": "",
    "lastUpdateSource": "",
    "reports": {
        "lastReportStatus": "",
        "lastReportUser": "",
        "lastReportDate": "",
        "beforeLastReportStatus": "",
        "beforeLastReportUser": "",
        "beforeLastReportDate": "",
        "antepenultimateReportStatus": "",
        "antepenultimateReportUser": "",
        "antepenultimateReportDate": ""
    },
    "coordinates": {
        "latitude": 0,
        "longitude": 0
    },
    "isFlashed": false,
    "reportCount": 0
}

const status_detailed = ["Unknown","Definitively Dead","Wild Reactivation","Destroyed","Destroyed","Very Damaged","Covered","Inaccessible","Slightly Damaged","Alive"]

const status_detailed_admin = ["Unknown","Definitively Dead","Wild Reactivation","Destroyed","Activation Pending","Very Damaged","Covered","Inaccessible","Slightly Damaged","Alive"]

const status_simplified = ["Unknown","Dead","Dead","Dead","Dead","Dead","Alive","Alive","Alive","Alive"]

const status_style = ["unknown", "definitivelyDead", "dead", "dead", "dead", "dead", "unflashable", "unflashable", "alive", "alive"]

const status_description = [
    "We have no information about the status of this invader",
    "This invader landed on a place that no longer exists. Any reactivation appears impossible",
    "The mosaic currently in place has not been validated by Invader. It is a wild reactivation. Don't loose your time, it can't be flashed",
    "This invader has been destroyed. It can't be flashed anymore but a reactivation remains possible",
    "This piece has been reactivated very recently. It can't be flashed yet. In order to protect the mosaic, please remain quiet about its reactivation",
    "Some parts of the mosaic remain visible, however this invader is very deteriorated and it cannot be flashed with FlashInvaders anymore",
    "This Invader has been covered (paint, poster, advertising, tape, etc.). It remains alive and flashable but probably requires some extra effort",
    "This invader has been trapped (restricted perimeter, building work, roadwork, etc.) or it landed on a private property. It remains alive and flashable but it can't be reached easily",
    "This invader has been partly deteriorated but it remains flashable. Extra effort might be required. Free tips, try under different lighting conditions, angles or zoom levels",
    "This invader is in great shape. Note that it might be the original mosaic or a reactivation"
]

const status_available_reports = {
    9:["8", "7", "6", "5", "4", "3"],
    8:["9", "7", "6", "5", "4", "3"],
    7:["9", "8", "5", "4", "3"],
    6:["9", "8", "5", "4", "3"],
    5:["9", "8", "7", "6", "4", "3"],
    4:["-1"],
    3:["9", "8", "7", "6", "4"],
    0:["9","8", "7", "6", "5", "4", "3"]
}

var options = {
    place: 'tc',
    message: (
        <div>
            <div>
                <b>Error</b> - An unknown error has occured.
            </div>
        </div>
    ),
    type: "danger",
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 7
}

const photos = [
  {
    content: (
      <img
        alt="Empry"
        className="d-block"
        src={"https://ressources.invadex.space/images/no-image.png"}
      />
    ),
    altText: "",
    caption: "",
    src: "0"
  }
];

class InvaderProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            invader: invader,
            photos: photos,
            isLocalized: false,
            isLocalisedClass: "btn btn-round btn-icon btn-inactive",
            isFlashed: false,
            swithFlashedDisabled: false,
            linkToMap: "/map?localize=" + invader.id + "&zone=" + invader.zone,
            settingCoordinatesDisplay: { display: "none" },
            statusUpdateChoice :
            /*<>
                <DropdownItem
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    this.confirmStatus(true);
                  }}
                >
                  Confirm this status
                </DropdownItem>
            </>*/"",
        };
    }

    onExiting = () => {
        this.animating = true;
    };
    onExited = () => {
        this.animating = false;
    };

    next = () => {
        if (this.animating) return;
        const nextIndex =
          this.state.activeIndex === this.state.photos.length - 1
            ? 0
            : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    };

    previous = () => {
        if (this.animating) return;
        const nextIndex =
          this.state.activeIndex === 0
            ? this.state.photos.length - 1
            : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    };

    goToIndex = newIndex => {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    };

    async getInvader(){
      try {
          const token = await this.props.getTokenSilently();

          const search = this.props.location.search; // could be '?foo=bar'
          const params = new URLSearchParams(search);
          const invaderUID = params.get('siUid'); // bar
          var formdata = new FormData();
          formdata.append("siUid", invaderUID);

          const response = await fetch("https://secure.api.invadex.space/private/getInvader",
              {
                  method: 'POST',
                  headers: {
                      Authorization: `Bearer ${token}`
                  },
                  mode: 'cors',
                  cache: 'default',
                  body: formdata
              }
          );

          const responseData = await response.json();

          this.setState({ invader: responseData });

          // images
          var photos = this.state.photos;
          photos = [
              {
                  content: (
                    <img
                        alt={this.state.invader.photoIDSource}
                        className="d-block"
                        src={this.state.invader.photoIDURL}
                    />
                  ),
                  altText: "",
                  caption: "",
                  src: "0"
              }
          ];
          var photosListURL = this.state.invader.photosURLList
          var photosListSources = this.state.invader.photosSourcesList
          var photosArrayURL = photosListURL.split(';');
          var photosArraySources = photosListSources.split(';');
          photosArrayURL.map((photoURL, key) => {
              if(photoURL!==""){
                  var photoElement =
                      {
                        content: (
                          <img
                            alt=""
                            className="d-block"
                            src={photoURL}
                          />
                        ),
                        altText: "",
                        caption: "",
                        src: "0"
                    };
                  photos.push(photoElement);
              }
          })
          this.setState({ photos : photos })

          // lastUpdateAgo
          var today = new Date();
          var createdOn = new Date(this.state.invader.lastUpdateDate);
          var msInDay = 24 * 60 * 60 * 1000;
          createdOn.setHours(0,0,0,0);
          today.setHours(0,0,0,0)
          var lastUpdateAgo = (+today - +createdOn)/msInDay
          lastUpdateAgo = Math.round(lastUpdateAgo);
          if(lastUpdateAgo < 30){
              if(lastUpdateAgo === 0){
                  lastUpdateAgo = "today";
              } else if(lastUpdateAgo === 1){
                  lastUpdateAgo = "1 day ago";
              } else {
                  lastUpdateAgo = lastUpdateAgo + " days ago";
              }
          } else if (lastUpdateAgo < 365) {
              lastUpdateAgo = lastUpdateAgo/12;
              lastUpdateAgo = Math.round(lastUpdateAgo);
              if(lastUpdateAgo === 1){
                  lastUpdateAgo = "1 month ago";
              } else {
                  lastUpdateAgo = lastUpdateAgo + " months ago";
              }
          } else {
              lastUpdateAgo = lastUpdateAgo/365;
              lastUpdateAgo = Math.round(lastUpdateAgo);
              if(lastUpdateAgo === 1){
                  lastUpdateAgo = "1 year ago";
              } else {
                  lastUpdateAgo = lastUpdateAgo + " years ago";
              }
          }
          this.setState({ lastUpdateAgo : lastUpdateAgo });

          // last Seen Ago
          var lastSeenDateRaw = new Date(this.state.invader.reports.lastReportDate);
          if(!isNaN(lastSeenDateRaw)) {
              lastSeenDateRaw.setHours(0,0,0,0);
              var lastSeenDateAgo = (+today - +lastSeenDateRaw)/msInDay
              lastSeenDateAgo = Math.round(lastSeenDateAgo);
              if(lastSeenDateAgo < 30){
                  if(lastSeenDateAgo === 0){
                      lastSeenDateAgo = "today";
                  } else if(lastSeenDateAgo === 1){
                      lastSeenDateAgo = "1 day ago";
                  } else {
                      lastSeenDateAgo = lastSeenDateAgo + " days ago";
                  }
              } else if (lastSeenDateAgo < 365) {
                  lastSeenDateAgo = lastSeenDateAgo/12;
                  lastSeenDateAgo = Math.round(lastSeenDateAgo);
                  if(lastSeenDateAgo === 1){
                      lastSeenDateAgo = "1 month ago";
                  } else {
                      lastSeenDateAgo = lastSeenDateAgo + " months ago";
                  }
              } else {
                  lastSeenDateAgo = lastSeenDateAgo/365;
                  lastSeenDateAgo = Math.round(lastSeenDateAgo);
                  if(lastSeenDateAgo === 1){
                      lastSeenDateAgo = "1 year ago";
                  } else {
                      lastSeenDateAgo = lastSeenDateAgo + " years ago";
                  }
              }
              lastSeenDateAgo = "Last seen " + lastSeenDateAgo
              if(this.state.invader.reports.lastReportDate!=="") {
                  lastSeenDateAgo = lastSeenDateAgo + " by " + this.state.invader.reports.lastReportUser
              }
          } else {
              lastSeenDateAgo = "Never seen";
          }
          this.setState({ lastSeenDateAgo : lastSeenDateAgo });

          // Invasion date
          var invasionDate = this.state.invader.invasionDate;
          if(invasionDate === "0000-00-00" || invasionDate === ""){
            invasionDate = "N.A."
          } else {
            invasionDate = new Date(invasionDate);
            invasionDate = invasionDate.toLocaleDateString();
          }
          this.setState({ invasionDate: invasionDate });

          // Zone - OK
          var zone = this.state.invader.zone
          if (zone.includes("paris")) {
              let re = /paris(\d+)/;
              var match = re.exec(zone);
              var quartier;
              if (match[1] === 1){
                  quartier = "1er";
              } else {
                  quartier = match[1]+"ème";
              }
              zone = "Paris (" + quartier + ")";
          }
          this.setState({ zone: zone });

          // Points - OK
          var points = this.state.invader.points
          if (points==="0") {
              points = "N.A.";
          } else {
              points = points + " PTS"
          }
          this.setState({ points: points });

          // Status
          var statusInt = this.state.invader.statusDelta;

          var detailedStatusString = status_detailed[statusInt];
          this.setState({ detailedStatusString: detailedStatusString });

          var statusStyle = status_style[statusInt];
          this.setState({ statusStyle: statusStyle });

          var statusString = status_simplified[statusInt];
          this.setState({ statusString: statusString });

          // isFlashed
          console.log("IsFlashed State", this.state.invader.isFlashed)
          if(this.state.invader.isFlashed === true){
              this.setState({ isFlashed: true })
          } else {
              this.setState({ isFlashed: false })
          }

          // isSpotted
          if(this.state.invader.coordinates.latitude !== 0) {
              this.setState({ linkToMap: "/map?center=" + this.state.invader.id + "&latitude=" + this.state.invader.coordinates.latitude + "&longitude=" + this.state.invader.coordinates.longitude})
              this.setState({ isLocalisedClass: "btn btn-round btn-icon btn-active" })
              this.setState({ isLocalized: true })
          } else {
              this.setState({ linkToMap: "/map?localize=" + this.state.invader.id + "&zone=" + this.state.invader.zone })
          }

          // Comments
          if(this.state.invader.comment.text !== "" && this.state.invader.comment.text !== undefined){
              let commentsBloc =
                      <>
                          <h4><b>Comments</b></h4>
                          <Table>
                              <tbody>
                                  <tr>
                                      <td>{this.state.invader.comment.text}</td>
                                  </tr>
                                  <tr>
                                      <td className="comments-source">-- {this.state.invader.comment.source}</td>
                                  </tr>
                              </tbody>
                          </Table>
                       </>

              this.setState({ commentsBloc: commentsBloc })
          }

      } catch (error) {
          console.error(error);
      }
    };

    async reportFlash(isFlashed){
      try {
          this.setState({
              isFlashed: !this.state.isFlashed
          });

          const token = await this.props.getTokenSilently();

          const search = this.props.location.search; // could be '?foo=bar'
          const params = new URLSearchParams(search);
          const invaderUID = params.get('siUid'); // bar
          var formdata = new FormData();
          formdata.append("siUid", invaderUID);
          if(isFlashed !== true){
              formdata.append("remove", "1");
          }

          const response = await fetch("https://secure.api.invadex.space/private/reportFlash",
              {
                  method: 'POST',
                  headers: {
                      Authorization: `Bearer ${token}`
                  },
                  mode: 'cors',
                  cache: 'default',
                  body: formdata
              }
          );

          const responseData = await response.json();

          if(responseData.error === 0){
              this.setState({ swithFlashedDisabled: false});
          } else {
              this.setState({
                  isFlashed: !this.state.isFlashed
              });

              this.setState({ swithFlashedDisabled: false})
              this.showAlert();
          }

        } catch (error) {
            console.error(error);
        }
    };

    async confirmStatus(confirm){
        try {
            const token = await this.props.getTokenSilently();

            const search = this.props.location.search; // could be '?foo=bar'
            const params = new URLSearchParams(search);
            const invaderUID = params.get('siUid'); // bar
            var formdata = new FormData();
            formdata.append("siUid", invaderUID);
            var status = this.state.invader.status
            if(confirm === false){
                    if (status >= 5) {
                    status = 3
                } else {
                    status = 7
                }
            }
            formdata.append("status", status);

            const response = await fetch("https://secure.api.invadex.space/private/delta/reportUpdate",
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    mode: 'cors',
                    cache: 'default',
                    body: formdata
                }
            );

            const responseData = await response.json();

            if(responseData.error === 0){
                var statusStyle = "success";
                var statusString = "Alive";
                var oppositeStatusString = "Dead";
                if(status == 8){
                    statusStyle = "unflashable";
                    statusString = "Slightly Damaged";
                    oppositeStatusString = "Alive";
                } else if(status == 7){
                    statusStyle = "unflashable";
                    statusString = "Inaccessible";
                    oppositeStatusString = "Alive";
                } else if(status == 6){
                    statusStyle = "unflashable";
                    statusString = "Covered";
                    oppositeStatusString = "Alive";
                } else if(status == 5){
                    statusStyle = "danger";
                    statusString = "Very Damaged";
                    oppositeStatusString = "Alive";
                } else if(status >= 2 && status <= 4){
                    statusStyle = "danger";
                    statusString = "Dead";
                    oppositeStatusString = "Alive";
                } else if(status == 1){
                    statusStyle = "danger";
                    statusString = "Dead";
                    oppositeStatusString = "Alive";
                }
                this.setState({ statusStyle: statusStyle });
                this.setState({ statusString: statusString });
                this.setState({ oppositeStatusString: oppositeStatusString });
            } else {
                this.showAlert();
            }
        } catch (error) {
            console.error(error);
        }
    };

    handleSwitch(elem, state) {
        this.setState({ swithFlashedDisabled: true})
        this.reportFlash(state)
    }

    componentDidMount() {
        this.getInvader();
    }

    showAlert(){
        this.refs.notify.notificationAlert(options);
    }

    goToLocalize() {
        this.props.history.push("/map?localize=" + this.state.invader.id + "&zone=" + this.state.invader.zone);
    };

    async removeCoordinates() {
        try {
            const token = await this.props.getTokenSilently();

            const search = this.props.location.search; // could be '?foo=bar'
            const params = new URLSearchParams(search);
            const invaderUID = params.get('siUid'); // bar
            var formdata = new FormData();
            formdata.append("siUid", invaderUID);
            formdata.append("remove", "1");

            const response = await fetch("https://secure.api.invadex.space/private/reportSpot",
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    mode: 'cors',
                    cache: 'default',
                    body: formdata
                }
            );

            const responseData = await response.json();

            console.log("ResponseData", responseData)

            if(responseData.error === 0){
                this.setState({ isLocalized: false, isLocalisedClass: "btn btn-round btn-icon" })
            } else {
                this.showAlert();
            }

          } catch (error) {
              this.showAlert();
              console.error(error);
          }
    }

  render() {
    return (
      <>
      <div className="content">
          <div className="team-4">
            <Container>
              <NotificationAlert ref="notify" />
              <Row>
                <Col className="ml-auto mr-auto" lg="10">
                  <Card className="card-profile card-horizontal">
                    <Row>
                      <Col xl="7">
                          <Carousel
                            activeIndex={this.state.activeIndex}
                            next={this.next}
                            previous={this.previous}
                            interval={false}
                          >
                            <CarouselIndicators
                              className="mt-5"
                              items={this.state.photos}
                              activeIndex={this.state.activeIndex}
                              onClickHandler={this.goToIndex}
                            />
                            {this.state.photos.map((photo, key) => {
                              return (
                                <CarouselItem
                                  onExiting={this.onExiting}
                                  onExited={this.onExited}
                                  key={key}
                                >
                                    {photo.content}
                                </CarouselItem>
                              );
                            })}
                            <a
                              className="carousel-control-prev"
                              data-slide="prev"
                              href="#pablo"
                              onClick={e => {
                                e.preventDefault();
                                this.previous();
                              }}
                              role="button"
                            >
                              <Button
                                className="btn-icon btn-round"
                                color="default"
                                name="button"
                                size="sm"
                                type="button"
                              >
                                <i className="tim-icons icon-minimal-left" />
                              </Button>
                            </a>
                            <a
                              className="carousel-control-next"
                              data-slide="next"
                              href="#pablo"
                              onClick={e => {
                                e.preventDefault();
                                this.next();
                              }}
                              role="button"
                            >
                              <Button
                                className="btn-icon btn-round"
                                color="default"
                                name="button"
                                size="sm"
                                type="button"
                              >
                                <i className="tim-icons icon-minimal-right" />
                              </Button>
                            </a>
                          </Carousel>
                      </Col>
                      <Col xl="5">
                        <CardBody className="mt-3 text-left">
                          <UncontrolledDropdown className="float-right">
                            <DropdownToggle
                              aria-expanded={false}
                              aria-haspopup={true}
                              caret
                              className="btn-block"
                              color={this.state.statusStyle}
                              data-toggle="dropdown"
                              id="dropdownMenuButton"
                              type="button"
                            >
                              {this.state.statusString}
                            </DropdownToggle>
                            <DropdownMenu right aria-labelledby="dropdownMenuButton">
                              <DropdownItem header>
                                Status updates are not yet available on the web version of Invadex
                              </DropdownItem>
                              {this.state.statusUpdateChoice}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          <CardTitle tag="h1">{this.state.invader.name}</CardTitle>
                          <h6 className="card-category">{this.state.lastSeenDateAgo}</h6>
                          <br />
                          <Table>
                            <tbody>
                                <tr>
                                    <td>
                                        Flashed<br/>
                                        <Switch
                                          value={this.state.isFlashed}
                                          onColor="default"
                                          onChange={(el, state) => this.handleSwitch(el, state)}
                                          offColor="default"
                                          disabled={this.state.swithFlashedDisabled}
                                        />
                                    </td>
                                    <td>&nbsp;</td>
                                    <td className="text-right">
                                        Localised<br/>
                                        <Router history={history}>
                                            <NavLink
                                              to={this.state.linkToMap}
                                            >
                                                <button type="button" rel="tooltip" class={this.state.isLocalisedClass}>
                                                    <i class="tim-icons icon-pin"></i>
                                                </button>
                                            </NavLink>
                                        </Router>
                                    </td>
                                </tr>
                            </tbody>
                          </Table>
                          <UncontrolledDropdown className="float-right" style={{ display: this.state.isLocalized ? "block" : "none" }}>
                              <DropdownToggle
                                caret
                                className="btn-icon"
                                color="link"
                                data-toggle="dropdown"
                                type="button"
                              >
                                <i className="tim-icons icon-settings-gear-63" />
                              </DropdownToggle>
                              <DropdownMenu right>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => {
                                        e.preventDefault();
                                        this.goToLocalize();
                                      }
                                  }
                                >
                                  Modify Coordinates
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => {
                                      e.preventDefault();
                                      this.removeCoordinates();
                                    }
                                  }
                                >
                                  Deletes Coordinates
                                </DropdownItem>
                              </DropdownMenu>
                          </UncontrolledDropdown>
                          <h4><b>About this Space Invader</b></h4>
                          <Table responsive>
                              <tbody>
                                <tr>
                                    <td>Invasion date</td>
                                    <td className="text-right">{this.state.invasionDate}</td>
                                </tr>
                                <tr>
                                    <td>Zone</td>
                                    <td className="text-right">{this.state.zone}</td>
                                </tr>
                                <tr>
                                    <td>Points</td>
                                    <td className="text-right">{this.state.points}</td>
                                </tr>
                                <tr>
                                    <td>Detailed status</td>
                                    <td className="text-right">{this.state.detailedStatusString}</td>
                                </tr>
                            </tbody>
                          </Table>
                          {this.state.commentsBloc}
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
      </div>
      </>
    );
  }
}

export default InvaderProfile;
