import React from "react";
import { Container } from "reactstrap";
import loading from "../assets/loading.svg";

//import "assets/css/blk-design-system-react.css";

const Loading = () => (
    <div className="wrapper">
        <div className="page-header header-filter">
            <Container>
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
                    <div className="spinner">
                        <img src={loading} alt="Loading" />
                    </div>
                </div>
            </Container>
        </div>
    </div>
);

export default Loading;
