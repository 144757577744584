import React from "react";
import ReactDOM from 'react-dom';
import { Router, NavLink } from "react-router-dom";
import { Card, CardImg, CardImgOverlay, CardBody, CardTitle, CardSubtitle, CardText, Row, Col } from "reactstrap";
import { CollectionView, GridLayout, Size, Insets } from "collection-view"
import LiveCellCard from "components/Cards/LiveCellCard"
import Loading from "components/Loading"
import history from "utils/history";

const city = {
    "name": "",
    "cityCode": "",
    "latitude": "",
    "longitude": "",
    "points": "",
    "waveCount": "",
    "nbSpaceInvader": "",
    "country": "",
    "flashedCount": "",
    "notDefinitivelyDeadCount": "",
    "aliveCount": ""
}

class Content extends React.Component {

    constructor(props) {
        super(props)
        this.items = props.items
    }

    getCount() {
        return this.items.length
    }

    configureElement(element, index) {
        const item = this.items[index]
        ReactDOM.render(<LiveCellCard live={item} />, element)
    }

    installView(element) {
        const layout = new GridLayout({
            itemSize: new Size(250, 340),
            insets: new Insets(10, 10, 10, 10)
        })
        var collectionView = new CollectionView(element, layout, this)
        collectionView.thresholds = {left: 0, top: 0, right: 0, bottom: 80000}
        this.view = collectionView
    }

    onScroll(view) {
        console.log("onScroll")
        if (this.loadingMore)
          return

        const end = this.view.scrollPosition.y
                    + this.view.containerSize.height
                    + Content.LOAD_THRESHOLD

        if (end < this.view.contentSize.height)
          return

        this.loadingMore = true

        setTimeout(() => {
          this.addMore(this.view)
          this.loadingMore = false
      }, Content.DELAY)
    }

    addMore(view) {
        console.log("Add More")
    }

    uninstallView() {
        if (!this.view) {
            return
        }

        this.view.uninstall((element => {
            // elements were rendered using React, clean up
            ReactDOM.unmountComponentAtNode(element)
        }))
    }

    onRef = (element) => {
        if (!element) {
            this.uninstallView()
            return
        }
        this.installView(element)
    }

    shouldComponentUpdate() {
        // prevent component from re-rendering
        return false
    }

    componentWillUnmount() {
        this.uninstallView()
    }

    render() {
        return (
            <div id="wrapper">
                <div id="scroll" ref={this.onRef}>
                </div>
            </div>
        )
    }
}

class CityLive extends React.Component {
    constructor(props) {
         super(props)
         this.state = {
             changed: false,
             invaders: <Loading /> ,
             token: '',
             cityCode: '',
             city: this.props
         }
     }

     onChangeClick = () => {
        this.setState((prevState) => ({
            changed: !prevState.changed
        }))
    }

    onHideClick = () => {
        this.setState((prevState) => ({
            hidden: !prevState.hidden
        }))
    }

    async getCityLive(){
      try {
        const token = await this.props.getTokenSilently();

        const search = this.props.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);
        const cityCode = params.get('cityCode'); // bar
        var formdata = new FormData();
        formdata.append("cityCode", cityCode);

        const response = await fetch("https://secure.api.invadex.space/private/getCityLive",
            {
              method: 'POST',
              headers: {
                  Authorization: `Bearer ${token}`
              },
              mode: 'cors',
              cache: 'default',
              body: formdata
            }
        );

        const responseData = await response.json();

        let lives = responseData.map((live, i) => {
            return (
                live
            )
        })
        lives = <Content items={lives} />

        this.setState({ lives : lives })

      } catch (error) {
        console.error(error);
      }
    };

    async getCities(){
      try {
        const token = await this.props.getTokenSilently();

        const response = await fetch("https://secure.api.invadex.space/private/getCities",
            {
              method: 'POST',
              headers: {
                  Authorization: `Bearer ${token}`
              },
              mode: 'cors',
              cache: 'default'
            }
        );

        const responseData = await response.json();

        let cities = responseData.map((city, i) => {
            if(city.cityCode == this.state.cityCode){
                this.setState({ city : city })
            }
        })

      } catch (error) {
        console.error(error);
      }
    };

    componentDidMount() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const cityCode = params.get('cityCode');

        console.log("City", this.props.city)

        this.setState({cityCode: cityCode})

        this.getCities()
        this.getCityLive()
    }

    render() {
          return [
              <div className="content">
                <Card className="cityHeader">
                    <CardImg width="100%" src={"https://ressources.invadex.space/images/cities-squared/" + this.state.cityCode + ".jpg"} alt="Card image cap" />
                        <CardImgOverlay>
                            <CardBody>
                                <table width="100%">
                                    <tr height="100px">
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr height="100px" class="linear-gradient-background">
                                        <td>
                                                <h2>{this.state.city.country}</h2>
                                                <h1>{this.state.city.name}</h1>
                                                <h3>{this.state.city.points} PTS</h3>
                                        </td>
                                        <td style={{textAlign: 'right', varticalAlign: 'bottom'}}>
                                            &nbsp;
                                            <h1>{this.state.city.flashedCount}</h1>
                                            <h3>/{this.state.city.nbSpaceInvader}</h3>
                                        </td>
                                    </tr>
                                </table>
                            </CardBody>
                        </CardImgOverlay>
                    </Card>
                    <div id="content" key="content">
                        {this.state.lives}
                    </div>
              </div>
          ]
      }
}

export default CityLive;
