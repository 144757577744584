import React from "react";
import ReactDOM from 'react-dom';
import { Router, NavLink } from "react-router-dom";
import { Card, CardImg, CardImgOverlay, CardBody, CardTitle, CardSubtitle, CardText, Row, Col } from "reactstrap";
import { CollectionView, GridLayout, Size, Insets } from "collection-view"
import InvaderCellCard from "components/Cards/InvaderCellCard"
import Loading from "components/Loading"
import history from "utils/history";

const city = {
    "name": "",
    "cityCode": "",
    "latitude": "",
    "longitude": "",
    "points": "",
    "waveCount": "",
    "nbSpaceInvader": "",
    "country": "",
    "flashedCount": "",
    "notDefinitivelyDeadCount": "",
    "aliveCount": ""
}

class Content extends React.Component {

    constructor(props) {
        super(props)

        // NOTE: *not* using state
        this.items = props.items
    }

    getCount() {
        return this.items.length
    }

    configureElement(element, index) {
        const item = this.items[index]
        console.log("Item on Configure", item)
        // render item using React
        ReactDOM.render(<InvaderCellCard invader={item} />, element)
    }

    installView(element) {
        const layout = new GridLayout({
            itemSize: new Size(300, 100),
            insets: new Insets(10, 10, 10, 10)
        })
        var collectionView = new CollectionView(element, layout, this)
        this.LOAD_THRESHOLD = 1000
        this.view = collectionView
    }

    uninstallView() {
        if (!this.view) {
            return
        }

        this.view.uninstall((element => {
            // elements were rendered using React, clean up
            ReactDOM.unmountComponentAtNode(element)
        }))
    }

    onRef = (element) => {
        if (!element) {
            this.uninstallView()
            return
        }
        this.installView(element)
    }

    shouldComponentUpdate() {
        // prevent component from re-rendering
        return false
    }

    componentWillUnmount() {
        this.uninstallView()
    }

    render() {
        return (
            <div id="wrapper">
                <div id="scroll" ref={this.onRef}>
                </div>
            </div>
        )
    }
}

class Search extends React.Component {
    constructor(props) {
         super(props)
         this.state = {
             changed: false,
             invaders: <Loading /> ,
             token: '',
             cityCode: '',
             city: city
         }
     }

     onChangeClick = () => {
        this.setState((prevState) => ({
            changed: !prevState.changed
        }))
    }

    onHideClick = () => {
        this.setState((prevState) => ({
            hidden: !prevState.hidden
        }))
    }

    async getSearch(){
      try {
        const token = await this.props.getTokenSilently();

        const search = this.props.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);
        const searchString = params.get('r'); // bar
        var formdata = new FormData();
        formdata.append("r", searchString);

        const response = await fetch("https://secure.api.invadex.space/private/search",
            {
              method: 'POST',
              headers: {
                  Authorization: `Bearer ${token}`
              },
              mode: 'cors',
              cache: 'default',
              body: formdata
            }
        );

        const responseData = await response.json();

        let invaders = responseData.map((invader, i) => {
            return (
                invader
            )
        })
        invaders = invaders.reverse();
        invaders = <Content items={invaders} />

        this.setState({ invaders : invaders })

      } catch (error) {
        console.error(error);
      }
    };

    componentDidMount() {
        this.getSearch()
    }

    render() {
          return [
              <div className="content">
                <Card className="cityHeader">
                    <CardImg width="100%" src={"https://ressources.invadex.space/images/cities-squared/PA.jpg"} alt="Card image cap" />
                        <CardImgOverlay>
                            <CardBody>
                                <table width="100%">
                                    <tr height="100px">
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr height="100px" class="linear-gradient-background">
                                        <td>
                                                <h2>&nbsp;</h2>
                                                <h1>Search results</h1>
                                                <h3>&nbsp;</h3>
                                        </td>
                                    </tr>
                                </table>
                            </CardBody>
                        </CardImgOverlay>
                    </Card>
                    <div id="content" key="content">
                        {this.state.invaders}
                    </div>
              </div>
          ]
      }
}

export default Search;
